import { css, html } from "lit";

import { LionInputAmount } from "@lion/input-amount";
import { loadDefaultFeedbackMessages } from "@lion/validate-messages";
import { LdsBaseInputMixin } from "../../../legl-ui/lds-input";
import { spacing } from "../../../legl-ui/lds-spacing";

export class LdsInputAmount extends LdsBaseInputMixin(LionInputAmount) {
  static get styles() {
    return [
      super.styles,
      css`
                :host
                    .input-group__container
                    > .input-group__input
                    ::slotted(.form-control) {
                    text-align: left;
                    padding-left: ${spacing.l};
                }

                :host ::slotted([slot="after"]) {
                    display: none;
                }

                :host ::slotted([slot="prefix"]) {
                    font-style: normal;
                }
            `,
    ];
  }

  static get properties() {
    return {
      currencyCode: { type: String, attribute: "currency-code" },
      locale: { type: String },
    };
  }

  getCurrencySymbol(currencyCode) {
    switch (currencyCode) {
      case "GBP":
        return "£";
      case "EUR":
        return "€";
      default:
        return "£";
    }
  }

  get slots() {
    return {
      ...super.slots,
      prefix: () => {
        const icon = document.createElement("i");
        icon.classList.add("currency-symbol");
        icon.innerHTML = this.getCurrencySymbol(this.currencyCode);
        return icon;
      },
    };
  }

  render() {
    loadDefaultFeedbackMessages();
    return super.render();
  }

  get sanitizedValue() {
    // Strip out comma from the amount
    return this.value.replaceAll(",", "");
  }
}

customElements.define("lds-input-amount", LdsInputAmount);
