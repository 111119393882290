import { LitElement, css, html } from "lit";
import "../../../legl-ui/lds-button";
import { neutral, primary } from "../../../legl-ui/lds-colours";
import { spacing } from "../../../legl-ui/lds-spacing";
import { getCookie } from "../../../legl-ui/utils";

export class CookieConsentModal extends LitElement {
  static get styles() {
    return css`
            .container {
                padding: 0 24px 24px 24px;
            }
            p {
                margin: 0 0 24px 0;
                color: ${neutral["800"]};
            }
            a {
                color: ${primary["600"]};
                text-decoration: none;
            }
            .button-container {
                display: grid;
                grid-template-columns: 1fr;
                grid-gap: ${spacing.m};
            }
            @media (min-width: 620px) {
                .button-container {
                    grid-template-columns: 1fr 1fr;
                }
            }
        `;
  }

  static get properties() {
    return {};
  }

  constructor() {
    super();
  }

  async connectedCallback() {
    super.connectedCallback();
    if (getCookie("cookiesAccepted") === true) {
      window?.posthog?.opt_in_capturing();
    }
  }

  setCookieConsent(value) {
    const date = new Date();

    const expiryDate = new Date(
      date.setDate(date.getDate() + 60),
    ).toUTCString();
    document.cookie = `cookiesAccepted=${value}; expires=${expiryDate}; path=/; sameSite=Strict`;

    if (value) {
      window.posthog?.opt_in_capturing();
    } else {
      window.posthog?.opt_out_capturing();
    }

    this.close();
  }

  close() {
    this.parentElement.onClickClose();
  }

  render() {
    return html`
            <div class="container">
                <p>
                    We use essential cookies on our website in order to provide
                    a safe and reliable experience, and analytics cookies to
                    improve performance. By clicking ‘Accept’ you agree to use
                    all cookies, alternatively you can choose ‘Reject additional
                    cookies’ and continue to use the website with only essential
                    cookies enabled. You can find out more about how we use
                    cookies
                    <a href="https://legl.com/cookie-policy/" target="_blank"
                        >here.</a
                    >
                </p>
                <div class="button-container">
                    <lds-button
                        id="reject-cookies-button"
                        @click=${() => this.setCookieConsent(false)}
                        variant="outlined"
                    >
                        Reject additional cookies
                    </lds-button>
                    <lds-button
                        data-cy-accept-cookies
                        id="accept-cookies-button"
                        @click=${() => this.setCookieConsent(true)}
                        colour="primary"
                    >
                        Accept
                    </lds-button>
                </div>
            </div>
        `;
  }
}

customElements.define("legl-cookie-consent-modal", CookieConsentModal);
