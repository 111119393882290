import { css } from "lit";

export const spacing = {
  xxs: css`4px`,
  xs: css`8px`,
  s: css`16px`,
  m: css`24px`,
  l: css`32px`,
  xl: css`40px`,
};

export const setCustomSpacingProperties = (
  element = document.documentElement,
) => {
  Object.entries(spacing).forEach(([key, value]) => {
    element.style.setProperty(`--lds-spacing-${key}`, value.cssText);
  });
};
