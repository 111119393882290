import { LitElement, html, nothing } from "lit";
import "../../../../../legl-ui/lds-button";
import { inIframe } from "../../../../../legl-ui/utils";

export class IFrameCloseButton extends LitElement {
  connectedCallback() {
    super.connectedCallback();
    if (inIframe()) {
      window.parent.postMessage("leglPaymentComplete", "*");
    }
  }

  handleClick() {
    window.parent.postMessage("closeLeglModal", "*");
  }

  render() {
    if (!inIframe()) {
      return nothing;
    }

    return html`
            <lds-button
                colour="neutral"
                variant="outline"
                id="embedded-close-button"
                @click="${this.handleClick}"
            >
                <slot></slot>
            </lds-button>
        `;
  }
}

customElements.define("iframe-close-button", IFrameCloseButton);
