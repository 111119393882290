import { sidebarToggle } from "../../../../../static/js/lawfirm_sidebar.js";
import "../../../../legl-ui/fullscreen-loading-spinner";
import {
  LeglIsEmail,
  LeglIsNotCardNumber,
  LeglMaxAmount,
  LeglMinAmount,
  LeglPattern,
  LeglRequired,
} from "../../../../legl-ui/lds-input";
import "../../../../legl-ui/lds-input-amount";
import { toastService } from "../../../../legl-ui/toast";
import { debounce } from "../../../../legl-ui/utils";
import { put } from "../../../../legl-ui/utils/fetch";

function showPaymentMethodAlphaContent() {
  /*
    Remove locked state and show the payment method alpha content.
    */
  const paymentMethod = document.querySelector(
    "legl-payments-accordion[data-payment-method]",
  );
  paymentMethod.removeAttribute("locked");
  paymentMethod.setAttribute("show-alpha", "");
}

function showPaymentMethodBetaContent() {
  const paymentMethod = document.querySelector(
    "legl-payments-accordion[data-payment-method]",
  );
  paymentMethod.removeAttribute("show-alpha");
}

function showPayerDetailsAlphaContent() {
  const payerDetails = document.querySelector(
    "legl-payments-accordion[data-payer-details]",
  );
  payerDetails.setAttribute("show-alpha", "");
}

function showPayerDetailsBetaContent() {
  const payerDetails = document.querySelector(
    "legl-payments-accordion[data-payer-details]",
  );
  payerDetails.removeAttribute("show-alpha");
}

function showTermsAndConditionsOverlay() {
  const paymentMethodOverlay = document.querySelector(
    "[data-payment-method-overlay]",
  );
  paymentMethodOverlay.classList.add("show-now");
}

function hideTermsAndConditionsOverlay() {
  const paymentMethodOverlay = document.querySelector(
    "[data-payment-method-overlay]",
  );
  paymentMethodOverlay.classList.remove("show-now");
}

function removeTermsAndConditionsOverlay() {
  const paymentReference = document.querySelector(
    "[data-payment-reference]",
  ).value;

  // recordLatestAcceptTerms sends a put to record that the accept button has been clicked
  // by the user. This is not intended to be idempotent, i.e. if they refresh after
  // clicking it will record again. This is intended.
  // The endpoint will return a 404 if the payment is no longer open.
  const recordLatestAcceptTerms = async (paymentReference) => {
    const response = await put(`/api/payments/${paymentReference}/terms/`);
    if (response.ok) {
      hideTermsAndConditionsOverlay();
    } else if (response.status === 404) {
      location.reload();
    }
  };

  recordLatestAcceptTerms(paymentReference);
}

function hideContinueToPaymentButton() {
  const continueButton = document.querySelector(
    "[data-continue-to-payment-methods-button]",
  );
  continueButton.style.display = "none";
}

function showContinueToPaymentButton() {
  const continueButton = document.querySelector(
    "[data-continue-to-payment-methods-button]",
  );
  if (continueButton) {
    continueButton.style.display = "block";
  }
}

function addListenerToAgreeToTermsAndConditionsButton() {
  const agreeButton = document.querySelector(
    "[data-button-to-agree-to-terms-and-conditions]",
  );
  agreeButton?.addEventListener("click", removeTermsAndConditionsOverlay);
}

function addListenersToResetPaymentMethod() {
  const payerDetails = document.querySelector(
    "legl-payments-accordion[data-payer-details]",
  );
  payerDetails?.addEventListener("click-beta", () => {
    showPayerDetailsAlphaContent();
    showContinueToPaymentButton();
    hideTermsAndConditionsOverlay();
    showPaymentMethodBetaContent();
  });
}

function addListenerToShowAmountToTwoDecimalPlaces() {
  const amountInput = document.querySelector("[data-payment-amount]");
  amountInput?.addEventListener("change", () => {
    const enteredAmount = makePositive(Number(amountInput.value));
    amountInput.value = convertToTwoDecimalPlaces(enteredAmount);
  });
}

function makePositive(number) {
  /* Convert any negative number to a positive number. */
  return number < 0 ? number * -1 : number;
}

function convertToTwoDecimalPlaces(number) {
  return Number.parseFloat(number).toFixed(2);
}

function disableContinueButton() {
  const continueButton = document.querySelector(
    "[data-continue-to-payment-methods-button]",
  );
  continueButton.setAttribute("disabled", "");
}

function enableContinueButton() {
  const continueButton = document.querySelector(
    "[data-continue-to-payment-methods-button]",
  );
  continueButton.removeAttribute("disabled");
}

function checkPaymentSetupReady() {
  if (typeof paymentSetupReady !== "undefined" && !paymentSetupReady) {
    toastService.showError(
      "This site is not available at the moment. Please contact or chat with us below to resolve this, thank you.",
      false,
    );
    Intercom("show");
    const alpha = document.querySelector(
      "legl-payments-accordion[data-payer-details]",
    );
    const beta = document.querySelector("div[data-details-summary-text]");
    const button = document.querySelector(
      "[data-continue-to-payment-methods-button]",
    );
    const payment_details = document.querySelector(
      "[data-cy-payment-method-details]",
    );
    const header = document.querySelector(".heading-text");
    alpha.setAttribute("locked", "");
    alpha.removeAttribute("show-alpha");
    beta.style.display = "none";
    button.remove();
    payment_details.style.display = "none";
    header.classList.remove("heading-text");
  }
}

function passReferenceToPaymentWrapper() {
  const refInput = document.querySelector("[data-payment-reference]");
  const selector = "legl-payment-wrapper-new";

  let wrapper = document.querySelector(selector);

  if (wrapper === null) {
    wrapper = document.querySelector("legl-payment-wrapper");
  }
  wrapper.updatePayment(refInput.value);
}

$(function enableContinueToPaymentMethodsButtonIfPayerDetailsFormIsValid() {
  /*
    This disables the form before intercooler sends any ajax requests to account for being able to click submit before the ajax responses come back
    */
  $(window).on("beforeAjaxSend.ic", (event, element, data) => {
    disableContinueButton();
  });

  /*
    If the payer details form is valid, this function enables the 'continue to payment methods' button.
    */

  $(window).on("success.ic", (event, element, data) => {
    if (data.includes("data-payment-reference")) {
      showPaymentMethodAlphaContent();
      showPayerDetailsBetaContent();
      showTermsAndConditionsOverlay();
      hideContinueToPaymentButton();
    }
  });
  // This is a nasty hack to get the pay reference from intercooler to the wrapper
  // component
  $(window).on("nodesProcessed.ic", () => {
    passReferenceToPaymentWrapper();
  });

  document
    .querySelector("legl-payments-accordion")
    ?.addEventListener("click-beta", () => enableContinueButton());
});

const checkValidityOfFields = debounce(() => {
  const isValid = [
    ...document.querySelectorAll("form lds-input, form lds-input-amount"),
  ].every((field) => field.hasFeedbackFor.includes("error") === false);
  if (!isValid) {
    disableContinueButton();
  } else {
    enableContinueButton();
  }
}, 250);

const showValidationError = (input) => {
  const isValid = input.hasFeedbackFor.includes("error") === false;
  if (!isValid) {
    input.submitted = true;
  }
};

function addValidatorsToPayerDetailsForm() {
  // TODO: Figure out how we can map validation from Django Forms/Models more conveniently
  const invoiceReference = document.querySelector("#id_invoice_reference");
  const matterReference = document.querySelector("#id_matter_reference");
  const customReference = document.querySelector("#id_custom_reference");
  const amount = document.querySelector("#id_amount");

  const validators = {
    first_name: [new LeglRequired(), new LeglIsNotCardNumber()],
    last_name: [new LeglRequired(), new LeglIsNotCardNumber()],
    email: [new LeglRequired(), new LeglIsEmail()],
    invoice_reference: [],
    matter_reference: [],
    custom_reference: [],
    amount: [new LeglRequired(), new LeglIsNotCardNumber()],
  };

  if (amount) {
    const { min, max } = amount.dataset;

    if (min) {
      validators.amount.push(
        new LeglMinAmount(min, {
          currency: amount.currencyCode,
          locale: amount.locale,
        }),
      );
    }
    if (max) {
      validators.amount.push(
        new LeglMaxAmount(max, {
          currency: amount.currencyCode,
          locale: amount.locale,
        }),
      );
    }
  }

  if (invoiceReference) {
    if (
      !!invoiceReference.dataset.required &&
      JSON.parse(invoiceReference.dataset.required.toLowerCase())
    ) {
      validators.invoice_reference.push(new LeglRequired());
    }
    if (invoiceReference.dataset.regex) {
      validators.invoice_reference.push(
        new LeglPattern(invoiceReference.dataset.regex),
      );
    }
    validators.invoice_reference.push(new LeglIsNotCardNumber());
  }

  if (matterReference) {
    if (
      !!matterReference.dataset.required &&
      JSON.parse(matterReference.dataset.required.toLowerCase())
    ) {
      validators.matter_reference.push(new LeglRequired());
    }
    if (matterReference.dataset.regex) {
      validators.matter_reference.push(
        new LeglPattern(matterReference.dataset.regex),
      );
    }
    validators.matter_reference.push(new LeglIsNotCardNumber());
  }

  if (customReference) {
    if (
      !!customReference.dataset.required &&
      JSON.parse(customReference.dataset.required.toLowerCase())
    ) {
      validators.custom_reference.push(new LeglRequired());
    }
    if (customReference.dataset.regex) {
      validators.custom_reference.push(
        new LeglPattern(customReference.dataset.regex),
      );
    }
    validators.custom_reference.push(new LeglIsNotCardNumber());
  }

  document
    .querySelectorAll("form lds-input, form lds-input-amount")
    .forEach((input) => {
      input?.addEventListener("keyup", () => {
        disableContinueButton();
        checkValidityOfFields();
      });
      input?.addEventListener("blur", () => {
        showValidationError(input);
      });
      const name = input.getAttribute("name");
      if (!!name && name in validators) {
        input.validators = validators[name];
      }
    });
}

// window.onload was not working reliably on Firefox so we're using the traditional form.
window.addEventListener("load", () => {
  checkPaymentSetupReady();
  addValidatorsToPayerDetailsForm();
  addListenerToAgreeToTermsAndConditionsButton();
  addListenersToResetPaymentMethod();
  addListenerToShowAmountToTwoDecimalPlaces();
  sidebarToggle("open-sidebar", ".close-sidebar", true);
});

function scrollToTopOfPage() {
  window.scrollTo(0, 0);
}
