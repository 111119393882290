import { dedupeMixin } from "@lion/core";
import { css } from "lit";
import { danger, neutral, primary } from "../../../legl-ui/lds-colours";
import { spacing } from "../../../legl-ui/lds-spacing";
import { typographyPresets } from "../../../legl-ui/lds-typography";
export const LdsButtonStylesMixin = dedupeMixin(
  (superclass) =>
    class LdsButtonStylesMixin extends superclass {
      static get styles() {
        const styles = css`
            :host {
                border-radius: 4px;
                cursor: pointer;
                -webkit-appearance: none !important;
            }

            :host([disabled]:hover) {
                cursor: default;
            }

            :host([colour="primary"]) {
                color: white;
                background-size: 200% auto;
                background-image: linear-gradient(
                    to right,
                    ${primary.base} 0%,
                    #05614c 50%,
                    #05614c 100%
                );
                transition: 0.3s;
            }

            :host([colour="primary"][disabled]) {
                background-image: none;
                background-color: ${primary["300"]};
            }

            :host([colour="primary"]:hover) {
                background-position: right center;
            }

            :host([colour="primary"][variant="outlined"]) {
                color: ${primary.base};
                background-image: none;
                background-color: transparent;
                border: 1px solid ${primary.base};
            }

            :host([colour="primary"][variant="outlined"][disabled]) {
                border-color: ${primary["300"]};
            }

            :host([colour="primary"][variant="text"]) {
                background-image: none;
                color: ${primary.base};
                background-color: transparent;
            }

            :host([colour="primary"][variant="outlined"]:hover),
            :host([colour="primary"][variant="text"]:hover) {
                background-color: ${primary["200"]};
            }

            :host([colour="primary"][variant="outlined"][disabled]),
            :host([colour="primary"][variant="text"][disabled]) {
                background-color: transparent;
                color: ${primary["300"]};
            }

            :host([colour="neutral"]) {
                color: ${neutral["0"]};
                background-color: ${neutral["800"]};
            }

            :host([colour="neutral"][disabled]:not([is-loading])) {
                color: ${neutral["0"]};
                background-color: ${neutral["500"]};
            }

            :host(
                    [colour="neutral"][variant="text"][disabled]:not(
                            [is-loading]
                        )
                ) {
                color: ${neutral["500"]};
                background-color: ${neutral["0"]};
            }

            :host([colour="neutral"]:hover) {
                background-color: ${neutral["900"]};
            }

            :host([colour="neutral"][variant="outlined"]) {
                color: ${neutral["800"]};
                background-color: transparent;
                border: 1px solid ${neutral["800"]};
            }

            :host([colour="neutral"][variant="outlined"][disabled]) {
                border-color: ${neutral["500"]};
            }

            :host([colour="neutral"][variant="text"]) {
                color: ${neutral["800"]};
                background-color: transparent;
            }

            :host([colour="neutral"][variant="outlined"][disabled])
                :host([colour="neutral"][variant="text"][disabled]) {
                color: ${neutral["500"]};
            }

            :host([colour="neutral"][variant="outlined"]:hover),
            :host([colour="neutral"][variant="text"]:hover) {
                background-color: ${neutral["200"]};
            }

            :host([colour="danger"]) {
                color: white;
                background-size: 200% auto;
                background-image: linear-gradient(
                    to right,
                    ${danger.base} 0%,
                    #b8183f 50%,
                    #b8183f 100%
                );
                transition: 0.3s;
            }

            :host([colour="danger"][disabled]:not([is-loading])) {
                background-image: none;
                background-color: ${danger["300"]};
            }

            :host([colour="danger"]:hover) {
                background-position: right center;
            }

            :host([colour="danger"][variant="outlined"]) {
                color: ${danger.base};
                background-image: none;
                background-color: transparent;
                border: 1px solid ${danger.base};
            }

            :host([colour="danger"][variant="outlined"][disabled]) {
                border-color: ${danger["300"]};
            }

            :host([colour="danger"][variant="text"]) {
                background-image: none;
                color: ${danger.base};
                background-color: transparent;
            }

            :host([colour="danger"][variant="outlined"]:hover),
            :host([colour="danger"][variant="text"]:hover) {
                background-color: ${danger["200"]};
            }

            :host([colour="danger"][variant="outlined"][disabled]),
            :host([colour="danger"][variant="text"][disabled]) {
                background-color: transparent;
                color: ${danger["300"]};
            }

            :host([small]) {
                ${typographyPresets.mediumSemiBold};
                padding: 6px 10px;
                height: 34px;
            }

            :host([variant="outlined"]:not[small]) {
                padding: calc(${spacing.xxs} + ${spacing.xs} - 1px)
                    calc(${spacing.m} - 1px);
            }
        `;
        if (super.styles) {
          return [super.styles, styles];
        } else {
          return styles;
        }
      }
    },
);
