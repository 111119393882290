import { LdsToast } from "../../legl-ui/lds-toast";

export class ToastService {
  showError(message) {
    LdsToast.showError({ text: message });
  }

  showSuccess(message, timeout = 3000) {
    LdsToast.showSuccess({ text: message, autoClose: true });
  }

  showDefault(message, timeout = 3000) {
    LdsToast.showSuccess({ text: message, autoClose: true });
  }
}

export const toastService = new ToastService();
