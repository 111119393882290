import { css } from "lit";
import { neutral } from "../../legl-ui/lds-colours";

export const shadow = {
  100: css`0.0px 4.0px 10.0px 0px rgba(0, 0, 0, 0.06),0.0px 2.0px 4.0px 0px rgba(0, 0, 0, 0.06),0.0px 1.0px 2.0px 0px rgba(0, 0, 0, 0.08)`,
  200: css`0.0px 16.0px 16.0px -1.0px rgba(0, 0, 0, 0.04),0.0px 6.0px 12.0px -1.0px rgba(0, 0, 0, 0.05),0.0px 3.0px 8.0px -1.0px rgba(0, 0, 0, 0.06),0.0px 1.0px 4.0px -1.0px rgba(0, 0, 0, 0.08)`,
  300: css`0.0px 12.0px 32.0px 0px rgba(0, 0, 0, 0.12),0.0px 10.0px 16.0px 0px rgba(0, 0, 0, 0.16),0.0px 8.0px 8.0px 0px rgba(0, 0, 0, 0.2)`,
};

export const elevation = {
  100: css`
        box-shadow: ${shadow["100"]};
        background-color: ${neutral.white};
    `,
  200: css`
        box-shadow: ${shadow["200"]};
        background-color: ${neutral.white};
    `,
  300: css`
        box-shadow: ${shadow["300"]};
        background-color: ${neutral.white};
    `,
};

export const setCustomElevationProperties = (
  element = document.documentElement,
) => {
  Object.entries(shadow).forEach(([key, value]) => {
    element.style.setProperty(`--lds-shadow-${key}`, value.cssText);
  });
};
