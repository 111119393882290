export const STATUS_CONFIG = {
  sent: { icon: "lds-icon-Send", colour: "default" },
  reviewed: {
    icon: "lds-icon-CheckCircleFilled",
    slug: "",
    colour: "success",
  },
  "ready-for-review": {
    icon: "lds-icon-VisibilityOn",
    slug: "",
    colour: "warning",
  },
  "edd-required": {
    icon: "lds-icon-VisibilityOn",
    slug: "",
    colour: "warning",
  },
  paid: { icon: "lds-icon-CheckCircleFilled", slug: "", colour: "success" },
  transferred: { icon: "lds-icon-Transfers", slug: "", colour: "success" },
  "marked-paid": {
    icon: "lds-icon-CheckCircleFilled",
    slug: "",
    colour: "success",
  },
  completed: {
    icon: "lds-icon-CheckCircleFilled",
    slug: "",
    colour: "success",
  },
  "in-progress": { icon: "lds-icon-InProgress", slug: "" },
  processing: { icon: "lds-icon-SettingsFilled", slug: "" },
  "marked-closed": { icon: "lds-icon-CheckCircleFilled", slug: "" },
  created: { icon: "lds-icon-InProgress", slug: "" },
  "refund-processing": { icon: "lds-icon-SettingsOutlined", slug: "" },
  "partially-refunded": { icon: "lds-icon-PartialRefund", slug: "" },
  refunded: { icon: "lds-icon-Refund", slug: "" },
  // Payment plans
  due: { icon: "lds-icon-Clock", colour: "default" },
  overdue: { icon: "lds-icon-WarningFilled", colour: "danger" },
  scheduled: { icon: "lds-icon-ClockOutlined", colour: "default" },
  cancelled: { icon: "lds-icon-Cancel", colour: "default" },
};
