import { css } from "lit";

export const neutral = {
  1000: css`#000000`,
  900: css`#091C42`,
  800: css`#253658`,
  700: css`#42516E`,
  600: css`#5E6B84`,
  500: css`#7A859A`,
  400: css`#A9AFBD`,
  300: css`#C5CAD3`,
  200: css`#EDEFF2`,
  100: css`#F4F5F7`,
  0: css`#FFFFFF`,
  get white() {
    return this["0"];
  },
  get black() {
    return this["1000"];
  },
  get base() {
    return this["500"];
  },
  get shade() {
    return this["600"];
  },
  get tint() {
    return this["700"];
  },
  get contrast() {
    return neutral.black;
  },
};

export const primary = {
  100: css`#F6FEFC`,
  200: css`#DFF6F1`,
  300: css`#94D1C3`,
  400: css`#3FA68F`,
  500: css`#068165`,
  600: css`#05614C`,
  700: css`#044D3D`,
  800: css`#023B2D`,
  900: css`#01231B`,
  get base() {
    return this["500"];
  },
  get shade() {
    return this["600"];
  },
  get tint() {
    return this["700"];
  },
  get contrast() {
    return neutral.white;
  },
};

export const warning = {
  100: css`#FFF8EB`,
  200: css`#FFEECC`,
  300: css`#FFDD99`,
  400: css`#F6C25A`,
  500: css`#E5A219`,
  600: css`#CB850B`,
  700: css`#B86E00`,
  800: css`#995200`,
  900: css`#7A3D00`,
  get base() {
    return this["500"];
  },
  get shade() {
    return this["600"];
  },
  get tint() {
    return this["700"];
  },
  get contrast() {
    return neutral.black;
  },
};

export const danger = {
  100: css`#FFF5F8`,
  200: css`#FEE6EC`,
  300: css`#FAB2C4`,
  400: css`#F56689`,
  500: css`#E5194B`,
  600: css`#B8183F`,
  700: css`#8A122F`,
  800: css`#5C0C20`,
  900: css`#2E0610`,
  get base() {
    return this["500"];
  },
  get shade() {
    return this["600"];
  },
  get tint() {
    return this["700"];
  },
  get contrast() {
    return neutral.white;
  },
};

export const customColours = {
  leglGreen: css`#01B88E`,
  leglBlue: css`#4A70FA`,
  leglPurple500: css`#5A6698`,
};

export const setCustomColourProperties = (
  element = document.documentElement,
) => {
  Object.entries({
    primary,
    neutral,
    warning,
    danger,
    customColours,
  }).forEach(([palletteName, pallette]) => {
    Object.entries(pallette).forEach(([key, value]) => {
      element.style.setProperty(
        `--lds-colour-${palletteName}-${key}`,
        value.cssText,
      );
    });
  });
};
