import { dedupeMixin } from "@lion/core";
import { css } from "lit";
import { danger, neutral, primary } from "../../../legl-ui/lds-colours";
import { spacing } from "../../../legl-ui/lds-spacing";
import { typographyPresets, weight } from "../../../legl-ui/lds-typography";

export function handleErrorMessage(changedProperties, componentThis) {
  changedProperties.forEach((oldValue, propName) => {
    if (propName === "errorMessage") {
      let feedback = componentThis.querySelector(
        `#feedback-${componentThis.id}`,
      );
      if (feedback === null) {
        feedback = componentThis.querySelector("lion-validation-feedback");
      }
      let errorMessageElement =
        feedback.shadowRoot.querySelector("#errorMessage");
      if (!errorMessageElement) {
        errorMessageElement = document.createElement("div");
        errorMessageElement.setAttribute("id", "errorMessage");
        errorMessageElement.setAttribute("data-testid", "errorMessage");
        feedback.setAttribute(
          "data-testid",
          componentThis.name
            ? `${componentThis.name}-validation-feedback`
            : "validation-feedback",
        );
        feedback.shadowRoot.appendChild(errorMessageElement);
      }
      errorMessageElement.innerHTML = componentThis.errorMessage;
      if (componentThis.errorMessage) {
        feedback.setAttribute("has-error", "show");
      } else {
        feedback.removeAttribute("has-error");
        errorMessageElement.remove();
      }
    }
    componentThis.requestUpdate();
  });
}

export const LdsBaseInputMixin = dedupeMixin(
  (superclass) =>
    class LdsBaseInputMixin extends superclass {
      static get styles() {
        return [
          super.styles,
          css`
                        :host {
                            ${typographyPresets.body};
                            color: ${neutral["800"]};
                        }

                        :host ::slotted(label) {
                            ${typographyPresets.bodyBold};
                            display: inline-block;
                            margin-bottom: ${spacing.xxs};
                        }

                        :host([small]) ::slotted(label) {
                            ${typographyPresets.smallBold};
                        }

                        :host ::slotted([slot="help-text"]) {
                            ${typographyPresets.medium};
                            color: ${neutral["700"]};
                            display: flex;
                            margin-bottom: ${spacing.xxs};
                            margin-top: 0px;
                        }

                        :host([u-sr-only]) ::slotted([slot="help-text"]) {
                            margin-bottom: 0;
                        }

                        :host ::slotted(.form-control) {
                            ${typographyPresets.body};
                            color: ${neutral["800"]};
                            padding: 11px;
                            border: 1px solid ${neutral["300"]};
                            border-radius: 4px;
                            outline: none;
                        }

                        :host([small]) ::slotted(.form-control) {
                            ${typographyPresets.small};
                            padding: 7px 12px;
                        }

                        :host([prefix-icon]) ::slotted(.form-control) {
                            padding-left: 50px;
                        }

                        :host ::slotted(.form-control)::placeholder {
                            ${typographyPresets.body};
                            color: ${neutral["300"]};
                        }

                        :host([small]) ::slotted(.form-control)::placeholder {
                            ${typographyPresets.small};
                        }

                        :host .form-field__group-two {
                            display: flex;
                            flex-direction: column-reverse;
                        }

                        :host .form-field__group-two .input-group__container {
                            align-items: center;
                        }

                        :host
                            .form-field__group-two
                            .input-group__container
                            .input-group__prefix {
                            margin-left: ${spacing.s};
                            position: absolute;
                            display: flex;
                        }

                        ::slotted([slot="feedback"][type="error"]),
                        ::slotted([slot="feedback"][has-error="show"]) {
                            ${typographyPresets.small};
                            display: flex;
                            color: ${danger["500"]};
                            margin-bottom: ${spacing.xxs};
                        }

                        :host([shows-feedback-for~="error"])
                            ::slotted(.form-control) {
                            border-color: ${danger["500"]};
                        }

                        :host(:not([shows-feedback-for~="error"]))
                            ::slotted(.form-control:focus) {
                            border-color: ${primary["500"]};
                            outline: ${primary["500"]} solid 1px;
                        }

                        :host([shows-feedback-for~="error"])
                            ::slotted(.form-control:focus) {
                            outline: ${danger["500"]} solid 1px;
                        }

                        :host([disabled]) .form-field__label ::slotted(*),
                        :host([disabled]) .form-field__help-text ::slotted(*),
                        :host([readonly]) .form-field__label ::slotted(*),
                        :host([readonly]) .form-field__help-text ::slotted(*) {
                            color: ${neutral["800"]};
                        }

                        :host([disabled])
                            .input-group
                            ::slotted([slot="input"]),
                        :host([readonly])
                            .input-group
                            ::slotted([slot="input"]) {
                            background-color: ${neutral["200"]};
                            color: ${neutral["800"]};
                            border-color: ${neutral["300"]};
                        }

                        :host([readonly]) ::slotted([slot="prefix"]),
                        :host([disabled]) ::slotted([slot="prefix"]) {
                            color: ${neutral["800"]};
                        }

                        :host ::slotted(lds-icon) {
                            color: ${neutral["700"]};
                        }

                        :host ::slotted(pre) {
                            white-space: pre-wrap;
                        }

                        :host ::slotted(.semi-bold-label) {
                            font-weight: ${weight.semibold};
                        }

                        :host([u-sr-only]) .form-field__label {
                            border: 0;
                            clip: rect(0 0 0 0);
                            height: 1px;
                            margin: -1px;
                            overflow: hidden;
                            padding: 0;
                            position: absolute;
                            width: 1px;
                        }
                    `,
        ];
      }

      connectedCallback() {
        super.connectedCallback();
        this.classList.add("lds-input");
      }

      static get properties() {
        return {
          optional: { type: Boolean },
          errorMessage: { attribute: false },
          small: { reflect: true },
          semiBoldLabel: { type: Boolean },
        };
      }

      _showFeedbackConditionFor() {
        return this.submitted;
      }

      // Manual override to set and display the errorMessages when input is used with vee-validate
      updated(changedProperties) {
        super.updated(changedProperties);
        handleErrorMessage(changedProperties, this);
      }

      get slots() {
        return {
          ...super.slots,
          label: () => {
            const label = document.createElement("label");
            label.innerHTML = this.label;

            if (this.semiBoldLabel) label.classList.add("semi-bold-label");
            if (this.optional) {
              label.innerHTML = `${this.label} <span>(optional)</span>`;
            }
            this.label = null;
            return label;
          },
        };
      }
    },
);
