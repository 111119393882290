import { LitElement, css, html } from "lit";

class LeglNumber extends LitElement {
  static get styles() {
    return css`
            :host slot {
                display: none;
            }
        `;
  }
  static get properties() {
    return {
      value: {
        type: Number,
      },
      locale: {
        type: String,
      },
      options: {
        type: Object,
      },
    };
  }

  get computedOptions() {
    return this.options || {};
  }

  get formattedNumber() {
    const valueToFormat = this.value || this.textContent;
    if (Boolean(valueToFormat) || Number(valueToFormat) === 0) {
      if (!isNaN(valueToFormat)) {
        return new Intl.NumberFormat(
          this.locale || "en-GB",
          this.computedOptions,
        ).format(valueToFormat);
      }
      return valueToFormat;
    }
    return "-";
  }

  constructor() {
    super();
    this.locale = window.navigator.userLanguage || window.navigator.language;

    this.options = {};
  }

  render() {
    return html`<slot></slot>${this.formattedNumber}`;
  }
}

class LeglCurrency extends LeglNumber {
  static get properties() {
    return {
      currency: {
        type: String,
      },
    };
  }

  get computedOptions() {
    return {
      style: "currency",
      currency: this.currency || "GBP",
      ...super.computedOptions,
    };
  }
}

customElements.define("legl-number", LeglNumber);
customElements.define("legl-currency", LeglCurrency);
