import { LitElement, css, html } from "lit";
import { classMap } from "lit/directives/class-map.js";
import {
  danger,
  neutral,
  primary,
  warning,
} from "../../../legl-ui/lds-colours";

export class LdsIcon extends LitElement {
  static get styles() {
    return css`
            :host {
                display: inline-flex;
            }

            i[class^="lds-icon-"]:before,
            i[class*=" lds-icon-"]:before {
                font-family: lds-icon !important;
                font-style: normal;
                font-weight: normal !important;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-size: 24px;
            }

            i[class^="lds-icon-"],
            i[class*=" lds-icon-"] {
                display: inline-flex;
            }

            i[class^="lds-icon-"].small:before,
            i[class*=" lds-icon-"].small:before {
                font-size: 16px;
            }

            i[class^="lds-icon-"].large:before,
            i[class*=" lds-icon-"].large:before {
                font-size: 32px;
            }

            i[class^="lds-icon-"].xlarge:before,
            i[class*=" lds-icon-"].xlarge:before {
                font-size: 48px;
            }

            .warning {
                color: ${warning["600"]};
            }

            .danger {
                color: ${danger["500"]};
            }

            .primary {
                color: ${primary["500"]};
            }
            .neutral {
                color: ${neutral["900"]};
            }
        `;
  }

  static get properties() {
    return {
      name: {},
      colour: { type: String },
      small: { type: Boolean, reflect: true },
      large: { type: Boolean },
      xlarge: { type: Boolean },
    };
  }

  render() {
    return html`
            <style>
                i[data-icon]::before {
                    content: var(--${this.name});
                }
            </style>
            <i
                data-icon
                class="${classMap({
                  [this.name]: true,
                  [this.colour]: !!this.colour,
                  small: this.small,
                  large: this.large,
                  xlarge: this.xlarge,
                })}"
            ></i>
        `;
  }
}

if (!customElements.get("lds-icon")) {
  customElements.define("lds-icon", LdsIcon);
}
