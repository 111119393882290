const defaultOptions = {
  credentials: "same-origin",
};

const defaultPostHeaders = () => ({
  Accept: "application/json",
  "X-CSRFTOKEN": CSRF_TOKEN,
});

async function getStyle(method, path, options) {
  options = {
    ...defaultOptions,
    method: method,
    ...options,
  };
  return fetch(path, options);
}

export async function get(path, options) {
  return getStyle("GET", path, options);
}

async function postStyle(method, path, options) {
  options = options || {};
  const headers = options?.removeJSONHeader
    ? {
        ...defaultPostHeaders(),
        ...options.headers,
      }
    : {
        "Content-Type": "application/json",
        ...defaultPostHeaders(),
        ...options.headers,
      };
  options = {
    ...defaultOptions,
    method: method,
    ...options,
    headers: headers,
  };
  return await fetch(path, options);
}

export async function post(path, options) {
  return postStyle("POST", path, options);
}

export async function put(path, options) {
  return postStyle("PUT", path, options);
}

export async function patch(path, options) {
  return postStyle("PATCH", path, options);
}

export async function del(path, options) {
  return postStyle("DELETE", path, options);
}

export function pollOnInterval(
  path,
  options,
  onData,
  interval = 10000,
  cancelAfterTries = 5,
) {
  let pollTimeout;
  let isCancelled = false;
  let tryCount = 0;
  const cancel = () => {
    isCancelled = true;
    clearTimeout(pollTimeout);
    pollTimeout = null;
  };
  function getNext() {
    get(path, options)
      .then((res) => {
        if (res.ok) {
          res.json().then((data) => {
            if (onData) {
              onData(data);
            }
          });
        }
      })
      .finally(() => {
        tryCount += 1;
        if (tryCount >= cancelAfterTries) {
          cancel();
        }
        if (pollTimeout) {
          clearTimeout(pollTimeout);
          pollTimeout = null;
        }
        if (!isCancelled) {
          pollTimeout = setTimeout(getNext, interval);
        }
      });
  }
  getNext();
  return cancel;
}
