import { LionInput } from "@lion/input";
import { css, html, nothing } from "lit";
import "../../../legl-ui/lds-icon";
import { LdsBaseInputMixin } from "./lds-base-input-mixin.js";

export class LdsInput extends LdsBaseInputMixin(LionInput) {
  static get styles() {
    return [
      super.styles,
      css`
                :host
                    ([filled][readonly][shows-feedback-for=""])
                    .input-group:after {
                    content: "";
                }
            `,
    ];
  }
  static get properties() {
    return {
      prefixIcon: { type: String, attribute: "prefix-icon" },
    };
  }

  get slots() {
    return {
      ...super.slots,
      prefix: () => {
        const icon = document.createElement("lds-icon");
        icon.name = this.prefixIcon;
        return icon;
      },
    };
  }
}

if (!customElements.get("lds-input")) {
  customElements.define("lds-input", LdsInput);
}
