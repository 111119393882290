import { css } from "lit";

export const family = {
  sans: css`'Lato', sans-serif`,
};

export const size = {
  small: {
    size: css`12px`,
    lineHeight: css`16px`,
  },
  medium: {
    size: css`14px`,
    lineHeight: css`20px`,
  },
  body: { size: css`16px`, lineHeight: css`24px` },
  h1: { size: css`32px`, lineHeight: css`36px` },
  h2: { size: css`24px`, lineHeight: css`32px` },
  h3: { size: css`20px`, lineHeight: css`28px` },
  h4: { size: css`18px`, lineHeight: css`24px` },
};

export const weight = {
  normal: css`400`,
  bold: css`700`,
  semibold: css`500`,
};

export const typographyMixin = (_size, _weight, _family) => {
  if (!_family) {
    _family = "sans";
  }

  if (!_size) {
    _size = "body";
  }

  if (!_weight) {
    _weight = "normal";
  }

  //lit-plugin in vscode complains about this but it's still valid
  return css`
        font-family: ${family[_family]};
        font-size: ${size[_size].size};
        line-height: ${size[_size].lineHeight};
        font-weight: ${weight[_weight]};
    `;
};

export const typographyPresetArgs = {
  h1: ["h1", "bold", "sans"],
  h2: ["h2", "bold", "sans"],
  h3: ["h3", "bold", "sans"],
  h4: ["h4", "bold", "sans"],
  body: ["body", "normal", "sans"],
  bodySemiBold: ["body", "semibold", "sans"],
  bodyBold: ["body", "bold", "sans"],
  medium: ["medium", "normal", "sans"],
  mediumSemiBold: ["medium", "semibold", "sans"],
  mediumBold: ["medium", "bold", "sans"],
  small: ["small", "normal", "sans"],
  smallSemiBold: ["small", "semibold", "sans"],
  smallBold: ["small", "bold", "sans"],
};

export const typographyPresets = Object.entries(typographyPresetArgs).reduce(
  (presetsOut, [key, value]) => {
    return {
      ...presetsOut,
      [key]: typographyMixin(...value),
    };
  },
  {},
);

export const setCustomTypographyProperties = (
  element = document.documentElement,
) => {
  Object.entries({ family, size, weight }).forEach(
    ([propertyName, property]) => {
      Object.entries(property).forEach(([key, value]) => {
        if (propertyName === "size") {
          element.style.setProperty(
            `--lds-typography-line-height-${key}`,
            value.lineHeight.cssText,
          );
          element.style.setProperty(
            `--lds-typography-font-size-${key}`,
            value.size.cssText,
          );
        } else {
          element.style.setProperty(
            `--lds-typography-${propertyName}-${key}`,
            value.cssText,
          );
        }
      });
    },
  );
};
