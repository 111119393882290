import { scss } from "./lawfirm_sidebar.scss";

export function sidebarToggle(
    open_selector,
    close_selector,
    disable_body_overflow = false
) {
    const open_sidebar = document.getElementById(open_selector);
    const close_sidebar = document.querySelectorAll(close_selector);
    const sidebar = document.getElementById("sidebar");
    const overlay = document.getElementsByClassName("overlay")[0];
    open_sidebar?.addEventListener("click", () => {
        overlay.style.display = "block";
        sidebar.classList.remove("close-sidebar");
        sidebar.classList.add("open-sidebar");
        if (disable_body_overflow) {
            document.body.setAttribute("style", "overflow: hidden");
        }
    });
    close_sidebar.forEach((item) => {
        item.addEventListener("click", () => {
            overlay.style.display = "none";
            sidebar.classList.remove("open-sidebar");
            sidebar.classList.add("close-sidebar");
            if (disable_body_overflow) {
                document.body.setAttribute("style", "overflow: visible");
            }
        });
    });
}
