import { LitElement, css, html, nothing } from "lit";
import { classMap } from "lit/directives/class-map.js";
import "../../../legl-ui/lds-button";
import { neutral } from "../../../legl-ui/lds-colours";
import "../../../legl-ui/lds-icon";

export class LdsModal extends LitElement {
  static get styles() {
    return css`
            .container {
                position: fixed;
                top: 0;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                width: 100%;
                height: 100%;
                z-index: 1050;
                background-color: rgba(0, 0, 0, 0.5);
                font-family: "Lato", sans-serif;
                overflow-y: initial;
            }
            .content {
                display: flex;
                position: absolute;
                flex-direction: column;
                overflow: hidden auto;
                max-height: 80vh;
                width: calc(100% - 20px);
                flex-grow: 1;
                height: auto;
                background-color: ${neutral["0"]};
                box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.2),
                    0px 10px 16px rgba(0, 0, 0, 0.16),
                    0px 12px 32px rgba(0, 0, 0, 0.12);
                border-radius: 4px;
                margin: 90px 10px 0 10px;
                box-sizing: border-box;
            }
            @media (min-width: 620px) {
                .content {
                    width: 600px;
                }
                .width-auto {
                    width: auto;
                }
            }
            .title {
                padding: 24px 16px 24px 24px;
                color: #091c42;
                font-size: 18px;
                font-weight: 700;
                display: flex;
                align-items: center;
            }

            .title lds-icon {
                padding-right: 8px;
            }

            .title > h2 {
                margin: 0;
            }

            .default-content {
                padding: 0 24px 24px 24px;
                height: 100%;
                overflow: hidden auto;
                position: relative;
            }

            .default-content::before {
                content: "";
                position: -webkit-sticky;
                position: sticky;
                top: 0;
                margin-left: -24px;
                width: 600px;
                display: block;
                height: 2px;
                background: ${neutral["0"]};
                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08),
                    0px 2px 4px rgba(0, 0, 0, 0.06),
                    0px 4px 10px rgba(0, 0, 0, 0.06);
            }

            .default-content::after {
                content: "";
                position: absolute;
                top: 0;
                margin-left: -24px;
                width: 600px;
                display: block;
                height: 10px;
                background: ${neutral["0"]};
            }

            .default-buttons-container {
                padding-top: 24px;
                display: flex;
                width: 100%;
                justify-content: flex-end;
            }
            .primary-button {
                margin-left: 15px;
            }

            lds-icon-button {
                height: 24px;
                width: 24px;
                display: flex;
                align-items: center;
                margin-left: auto;
                border-radius: 50%;
                background: ${neutral["0"]};
                right: 10px;
                top: 20px;
            }
        `;
  }

  static get properties() {
    return {
      title: { attribute: false },
      description: { attribute: false },
      primaryButtonAttributes: { attribute: false },
      isLoading: { state: true },
    };
  }

  static create({
    title,
    description,
    titleIconName,
    primaryButtonAttributes,
    slotComponent,
    slotComponentAttributes,
    slotComponentProperties,
    hideCloseBtn = false,
    headerCloseBtn = false,
    closeOnClickOutside = false,
    widthAuto = false,
    dataTestId,
  }) {
    const modal = document.createElement("lds-modal");
    modal.title = title;
    modal.hideCloseBtn = hideCloseBtn;
    modal.headerCloseBtn = headerCloseBtn;
    modal.closeOnClickOutside = closeOnClickOutside;
    modal.titleIconName = titleIconName;
    modal.widthAuto = widthAuto;

    if (slotComponent) {
      const slot = document.createElement(slotComponent);
      slot.setAttribute("slot", "content");
      if (dataTestId) {
        slot.setAttribute("data-testid", dataTestId);
      }

      if (slotComponentAttributes) {
        for (const [key, value] of Object.entries(slotComponentAttributes)) {
          slot.setAttribute(key, value);
        }
      }
      if (slotComponentProperties) {
        for (const [key, value] of Object.entries(slotComponentProperties)) {
          slot[key] = value;
        }
      }
      modal.appendChild(slot);
    } else {
      modal.description = description;
      if (primaryButtonAttributes) {
        modal.primaryButtonAttributes = primaryButtonAttributes;
      }
    }

    document.body.appendChild(modal);
  }

  async onPrimaryActionClick() {
    this.isLoading = true;
    await this.primaryButtonAttributes.onClick();
    this.isLoading = false;
    this.close();
  }

  clickOutsideModal(event) {
    if (event.target.id === "lds-modal") this.close();
  }

  onClickClose() {
    this.close();
  }

  close() {
    if (this.parentElement) {
      this.parentElement.removeChild(this);
    }
  }

  render() {
    return html`<div
            id="lds-modal"
            class="container"
            @click=${this.closeOnClickOutside ? this.clickOutsideModal : null}
            @keydown=${this.closeOnClickOutside ? this.clickOutsideModal : null}
        >
            <div
                class="${classMap({
                  content: true,
                  "width-auto": this.widthAuto,
                })}"
            >
                <div id="lds-modal-title" class="title">
                    ${
                      this.titleIconName
                        ? html`<lds-icon
                              .colour=${
                                this.primaryButtonAttributes?.colour ??
                                "primary"
                              }
                              name=${this.titleIconName}
                          ></lds-icon>`
                        : nothing
                    }
                    ${this.title}
                    ${
                      this.headerCloseBtn
                        ? html`<lds-icon-button
                          colour="neutral"
                          name="lds-icon-Close"
                          @click=${this.onClickClose}
                      ></lds-icon-button>`
                        : nothing
                    }
                </div>
                
                <slot name="content">
                    <div id="lds-modal-default-content" class="default-content">
                        <p id="lds-modal-description">${this.description}</p>
                        <div class="default-buttons-container">
                            ${
                              !this.hideCloseBtn
                                ? html`
                                      <lds-button
                                          id="lds-modal-close-button"
                                          variant="outlined"
                                          colour="neutral"
                                          @click=${this.onClickClose}
                                          ?disabled=${this.isLoading}
                                          >Close</lds-button
                                      >
                                  `
                                : nothing
                            }
                            ${
                              this.primaryButtonAttributes
                                ? html`<lds-button
                                      id="lds-modal-primary-button"
                                      class="primary-button"
                                      variant=${this.primaryButtonAttributes.variant}
                                      colour=${this.primaryButtonAttributes.colour}
                                      @click=${this.onPrimaryActionClick}
                                      .isLoading=${this.isLoading}
                                      ?disabled=${this.isLoading}
                                      >${this.primaryButtonAttributes.label}</lds-button
                                  >`
                                : nothing
                            }
                        </div>
                    </div>
                </slot>
            </div>
        </div> `;
  }
}

customElements.define("lds-modal", LdsModal);
