import { LitElement, css, html } from "lit";

export class LeglFullscreenLoadingSpinner extends LitElement {
  static get styles() {
    return css`
            .loader {
                position: fixed;
                background: rgba(0, 0, 0, 0.78);
                z-index: 10000;
                height: 100%;
                top: 0;
                right: 0;
                left: 0;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                -webkit-transition: opacity 0.5s ease-in-out,
                    -webkit-transform 0.01s linear;
                transition: opacity 0.5s ease-in-out,
                    -webkit-transform 0.01s linear;
                -o-transition: opacity 0.5s ease-in-out, transform 0.01s linear;
                transition: opacity 0.5s ease-in-out, transform 0.01s linear;
                transition: opacity 0.5s ease-in-out, transform 0.01s linear,
                    -webkit-transform 0.01s linear;
                bottom: 0;
                -webkit-transform: translateY(0) scale(1);
                -ms-transform: translateY(0) scale(1);
                transform: translateY(0) scale(1);
                pointer-events: auto;
                opacity: 1;
            }
            .loader__circle {
                -webkit-box-flex: 0;
                -ms-flex: 0 0 auto;
                flex: 0 0 auto;
                height: 100px;
                width: 100px;
                -webkit-animation: rotation 0.8s infinite linear;
                animation: rotation 0.8s infinite linear;
            }
            .loader__circle circle {
                stroke: #29d273;
                stroke-width: 2;
                fill: transparent;
                stroke-dasharray: 207.24;
                stroke-dashoffset: 0;
            }

            @-webkit-keyframes rotation {
                from {
                    -webkit-transform: rotate(0deg);
                }
                to {
                    -webkit-transform: rotate(359deg);
                }
            }

            @keyframes rotation {
                from {
                    -webkit-transform: rotate(0deg);
                }
                to {
                    -webkit-transform: rotate(359deg);
                }
            }
        `;
  }

  render() {
    return html`
            <div class="loader">
                <svg
                    viewBox="0 0 100 100"
                    xmlns="http://www.w3.org/2000/svg"
                    class="loader__circle"
                >
                    <circle cx="50" cy="50" r="45" />
                </svg>
            </div>
        `;
  }
}
customElements.define(
  "legl-fullscreen-loading-spinner",
  LeglFullscreenLoadingSpinner,
);
