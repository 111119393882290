import { LitElement, css, html, nothing } from "lit";
import { classMap } from "lit/directives/class-map.js";
import { danger, neutral, primary } from "../../../legl-ui/lds-colours";
import { elevation } from "../../../legl-ui/lds-elevation";
import "../../../legl-ui/lds-icon";
import { spacing } from "../../../legl-ui/lds-spacing";
import { typographyPresets } from "../../../legl-ui/lds-typography";

export class LdsToast extends LitElement {
  static get styles() {
    return css`
            :host {
                display: block;
                width: 400px;
                position: relative;
                border-radius: 4px;
                padding: ${spacing.s};
                margin-bottom: ${spacing.s};
                ${elevation["200"]};
                color: ${neutral["800"]};
                box-sizing: border-box;
            }

            :host(.success) {
                background-color: ${primary["100"]};
                border: 1px solid ${primary["400"]};
            }

            :host(.error) {
                background-color: ${danger["100"]};
                border: 1px solid ${danger["400"]};
            }

            :host(.show) {
                -webkit-animation: fadein 0.2s forwards;
                animation: fadein 0.2s forwards;
                top: -100px;
            }

            :host(.close-toast) {
                -webkit-animation: fadeout 0.2s forwards;
                animation: fadeout 0.2s forwards;
            }

            @-webkit-keyframes fadein {
                100% {
                    top: 0;
                }
            }

            @keyframes fadein {
                100% {
                    top: 0;
                }
            }

            @-webkit-keyframes fadeout {
                0% {
                    top: 0;
                }
                100% {
                    top: -100px;
                }
            }

            @keyframes fadeout {
                0% {
                    top: 0;
                }
                100% {
                    top: -100px;
                }
            }

            #lds-toast .content-wrapper {
                display: flex;
                flex-direction: row;
            }

            #lds-toast .content {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                margin-right: 8px;
            }

            #lds-toast .title {
                ${typographyPresets.bodyBold};
            }

            #lds-toast .title.withText {
                margin-bottom: ${spacing.xs};
            }

            #lds-toast .text {
                ${typographyPresets.body};
            }

            svg {
                position: absolute;
                height: 30px;
                width: 30px;
                transform: rotateY(0deg) rotateZ(-90deg);
            }

            svg circle {
                stroke-dasharray: 62px;
                stroke-dashoffset: 0px;
                stroke-linecap: round;
                stroke-width: 2px;
                fill: none;
                animation: countdown 10s linear backwards;
            }

            @keyframes countdown {
                from {
                    stroke-dashoffset: 62px;
                }
                to {
                    stroke-dashoffset: 0px;
                }
            }

            @-webkit-keyframes countdown {
                from {
                    stroke-dashoffset: 62px;
                }
                to {
                    stroke-dashoffset: 0px;
                }
            }

            #close-button {
                display: flex;
                margin-left: ${spacing.xs};
                background-color: transparent;
                border: none;
                height: 20px;
                width: 20px;
                padding: 0px;
                border-radius: 50%;
                padding: 0px;
                overflow: hidden;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }

            #close-button:hover {
                border-radius: 50%;
            }

            #close-button.success:hover {
                background-color: ${primary["200"]};
            }

            #close-button.error:hover {
                background-color: ${danger["200"]};
            }

            #status-icon {
                margin-right: ${spacing.xs};
            }

            #status-icon.success {
                color: ${primary["600"]};
            }

            #status-icon.error {
                color: ${danger["500"]};
            }

            lds-icon[name="lds-icon-Close"] {
                position: absolute;
            }

            lds-icon:hover {
                cursor: pointer;
            }
        `;
  }

  static showError({ title = "", text = "", autoClose = false }) {
    const toast = new this("error", title, text, autoClose);
    toast.setAttribute("data-testid", "error-toast");
    toast.show();
  }

  static showSuccess({ title = "", text = "", autoClose = false }) {
    const toast = new this("success", title, text, autoClose);
    toast.setAttribute("data-testid", "success-toast");
    toast.show();
  }

  constructor(status, title, text, autoClose) {
    super();
    this.status = status;
    this.title = title;
    this.text = text;
    this.autoClose = autoClose;
    this.timerRef = null;
  }

  render() {
    const isSuccess = this.status === "success";
    return html`
            <div id="lds-toast" role="alert">
                <div class="content-wrapper">
                    <lds-icon
                        id="status-icon"
                        class="${classMap({
                          success: isSuccess,
                          error: !isSuccess,
                        })}"
                        .name=${
                          isSuccess
                            ? "lds-icon-CheckCircleFilled"
                            : "lds-icon-ErrorFilled"
                        }
                    ></lds-icon>
                    <div class="content">
                        ${
                          this.title
                            ? html`<div
                                  class=${classMap({
                                    title: true,
                                    withText: !!this.text,
                                  })}
                              >
                                  ${this.title}
                              </div>`
                            : nothing
                        }
                        ${
                          this.text
                            ? html`<div class="text">${this.text}</div>`
                            : nothing
                        }
                    </div>
                    <button
                        data-testid="close-button"
                        id="close-button"
                        class="${classMap({
                          success: isSuccess,
                          error: !isSuccess,
                        })}"
                        @click=${this.close}
                    >
                        <lds-icon
                            .small=${true}
                            name="lds-icon-Close"
                        ></lds-icon>
                        ${
                          this.autoClose
                            ? html` <svg>
                                  <circle
                                      stroke="${
                                        isSuccess
                                          ? primary["500"]
                                          : danger["500"]
                                      }"
                                      r="10"
                                      cx="15"
                                      cy="15"
                                  ></circle>
                              </svg>`
                            : nothing
                        }
                    </button>
                </div>
            </div>
        `;
  }

  show() {
    let wrapper = document.getElementById("toast-wrapper");
    if (!wrapper) {
      wrapper = document.createElement("div");
      wrapper.setAttribute("id", "toast-wrapper");
      document.body.appendChild(wrapper);
    }
    wrapper.style.cssText =
      "position: fixed; top: 0; left: 50%; transform: translateX(-50%); z-index: 2000; overflow: hidden; padding: 16px";
    const a = ["show", this.status];
    this.classList.add(...a);
    wrapper.appendChild(this);

    if (this.autoClose) {
      this.timerRef = setTimeout(() => this.close(), 10 * 1000);
    }
  }

  close() {
    const wrapper = document.getElementById("toast-wrapper");
    this.classList.add("close-toast");

    if (this.timerRef) clearTimeout(this.timerRef);

    setTimeout(() => {
      wrapper.removeChild(this);
    }, 200);
  }
}

if (!customElements.get("lds-toast")) {
  customElements.define("lds-toast", LdsToast);
}
