import { dedupeMixin } from "@lion/core";
import { html } from "lit";
import "../../../legl-ui/lds-spinner";

export const LdsButtonMixin = dedupeMixin(
  (superclass) =>
    class LdsButtonMixin extends superclass {
      static get properties() {
        return {
          colour: {
            reflect: true,
          },
          variant: {
            reflect: true,
          },
          isLoading: {
            type: Boolean,
            attribute: "is-loading",
          },
        };
      }

      constructor() {
        super();
        this.colour = "neutral";
        this.variant = "contained";
      }

      get loadingIcon() {
        return html` <lds-spinner></lds-spinner> `;
      }
    },
);
